<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm" label-width="80px">
              <el-form-item>
                <el-input v-model="searchForm.orderNo" placeholder="订单号" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.tradeNo" placeholder="交易单号" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.orderStatus" placeholder="订单状态" clearable>
                  <el-option v-for="item in basicData.orderStatus" :label="item.label" :value="item.value"
                             :key="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                    v-model="searchForm.startTime"
                    type="date"
                    unlink-panels
                    placeholder="创建时间起"
                    value-format="yyyy-MM-dd"
                    style="width: 187px"
                    :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                    v-model="searchForm.endTime"
                    type="date"
                    unlink-panels
                    placeholder="创建时间止"
                    value-format="yyyy-MM-dd"
                    style="width: 187px"
                    :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.orderSource" placeholder="订单来源" clearable>
                  <el-option v-for="item in basicData.orderSource" :label="item.label" :value="item.value"
                             :key="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.orderType" placeholder="订单类型" clearable>
                  <el-option v-for="item in basicData.orderType" :label="item.label" :value="item.value"
                             :key="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.payStatus" placeholder="支付状态" clearable>
                  <el-option v-for="item in basicData.payStatus" :label="item.label" :value="item.value"
                             :key="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.payWay" placeholder="支付方式" clearable>
                  <el-option v-for="item in basicData.payWay" :label="item.label" :value="item.value"
                             :key="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <remote-select v-model="searchForm.userId"
                               width="300"
                               placeholder="用户"
                               placement="bottom"
                               url="/user/list"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item>
                <el-button plain @click="handleSearch">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="mainTable.rows"
                v-loading="mainTable.loading"
                style="width: 100%">
              <el-table-column prop="orderNo" label="订单编号" width="220"></el-table-column>
              <el-table-column prop="tradeNo" label="交易单号" width="220"></el-table-column>
              <el-table-column prop="orderSource" label="订单来源">
                <template slot-scope="scope">
                  {{ getOrderSource(scope.row.orderSource) }}
                </template>
              </el-table-column>
              <el-table-column prop="orderType" label="订单类型">
                <template slot-scope="scope">
                  {{ getOrderType(scope.row.orderType) }}
                </template>
              </el-table-column>
              <el-table-column prop="orderDesc" label="订单描述" width="120"></el-table-column>
              <el-table-column prop="totalAmount" label="订单金额"></el-table-column>
              <el-table-column prop="payAmount" label="实付金额"></el-table-column>
              <el-table-column prop="orderStatus" label="订单状态">
                <template slot-scope="scope">
                  {{ getOrderStatus(scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column prop="payStatus" label="支付状态">
                <template slot-scope="scope">
                  {{ getPayStatus(scope.row.payStatus) }}
                </template>
              </el-table-column>
              <el-table-column prop="payWay" label="支付方式">
                <template slot-scope="scope">
                  {{ getPayWay(scope.row.payWay) }}
                </template>
              </el-table-column>
              <el-table-column prop="payTime" label="支付时间" width="140"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="140"></el-table-column>
              <!--          <el-table-column prop="cancelTime" label="取消时间"></el-table-column>-->
              <!--          <el-table-column prop="cancelRemark" label="取消原因"></el-table-column>-->
              <el-table-column prop="userNickName" label="用户" min-width="300">
                <template slot-scope="scope">
                  {{ getUserText(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column prop="creatorName" label="员工" width="110"></el-table-column>
              <el-table-column prop="creatorDeptName" label="部门" width="200"></el-table-column>
              <el-table-column prop="orderRemark" label="订单备注" width="200"></el-table-column>
              <!--          <el-table-column-->
              <!--              label="操作"-->
              <!--              width="200">-->
              <!--            <template slot-scope="scope">-->
              <!--              <el-button type="text">开通VIP</el-button>-->
              <!--            </template>-->
              <!--          </el-table-column>-->
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.page"
                :page-sizes="[10,20,50,100]"
                :page-size="searchForm.size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="mainTable.total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { OmsOrderApi } from '@/api'
import {
  getLabelName,
  ORDER_SOURCE,
  ORDER_STATUS,
  ORDER_TYPE,
  PAY_STATUS,
  PAY_WAY,
  SHORTCUTS
} from '@/utils/contants'

export default {
  name: 'Order',
  data () {
    return {
      searchForm: {
        page: 1,
        size: 10,
        orderNo: '',
        tradeNo: '',
        orderSource: '',
        orderType: '',
        orderStatus: '',
        payWay: '',
        payStatus: '',
        billStatus: '',
        userId: '',
        creatorOrgId: '',
        startTime: '',
        endTime: ''
      },
      mainTable: {
        loading: false,
        rows: [],
        total: 0
      },
      basicData: {
        orderStatus: ORDER_STATUS,
        orderSource: ORDER_SOURCE,
        payStatus: PAY_STATUS,
        orderType: ORDER_TYPE,
        payWay: PAY_WAY
      },
      pickerOptions: {
        shortcuts: SHORTCUTS
      }
    }
  },
  methods: {
    handleSearch () {
      this.mainTable.loading = true
      const params = {
        ...this.searchForm,
        page: this.searchForm.page - 1
      }
      OmsOrderApi.list(params).then(result => {
        this.mainTable.rows = result.rows
        this.mainTable.total = result.total
        this.mainTable.loading = false
      })
    },
    handleSizeChange (size) {
      this.searchForm.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.searchForm.page = page
      this.handleSearch()
    },
    getOrderStatus (val) {
      return getLabelName(ORDER_STATUS, val)
    },
    getOrderSource (val) {
      return getLabelName(ORDER_SOURCE, val)
    },
    getPayStatus (val) {
      return getLabelName(PAY_STATUS, val)
    },
    getPayWay (val) {
      return getLabelName(PAY_WAY, val)
    },
    getOrderType (val) {
      return getLabelName(ORDER_TYPE, val)
    },
    getUserText (row) {
      return `${row.userNickName}(${row.userMobile})`
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style>

</style>
